.card-item-black {
    position: relative;
    border-radius: 4px;
    background: #101010;
    padding: 12px;
    overflow: hidden;
}



.card-item-placeholder-header > h1 {
    color: #F4F2EF;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
}

.card-item-black-header {
    display: flex;
    gap: 24px;
    align-items: center;
}

.card-item-black-header > img {
    width: 48px;
    height: 48px;
}

.card-item-black-header > h3 {
    color: #F4F2EF;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
    background: #46cd6e;
    border: 2px solid #46cd6e;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    transition: all 100ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-btn:hover {
    scale: 1.1;
    border: 2px solid #F4F2EF
}

.card-btn > span {
    color: #1b1b1b;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 100ms ease-in-out;
}

.card-btn:hover > span {
    scale: 1.1;
}

/* Services Scrollable List Container */

.gig-planner-item {
    background-color: #1b1b1b;
    filter: brightness(1.25);
    -webkit-filter: brightness(1.25);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
}
.gig-planner-item > .desc {
    display: -webkit-box;
    max-width: inherit;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.gig-planner-item > h1 {
    color: #F4F2EF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.gig-planner-item:hover {
    filter: brightness(1.5);
    -webkit-filter: brightness(1.5);
    cursor: pointer;
}

.price-label {
    width: 16px;
    height: 16px;
    background: #46CD6E;
    border-radius: 4px;
}

.all-gigs-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    gap: 12px;
    justify-content: space-evenly;
}

.all-gigs-item {
    transition: all 150ms ease-in-out;
}

.all-gigs-container > .all-gigs-item {
    background-color: #1b1b1b;
    filter: brightness(1.25);
    -webkit-filter: brightness(1.25);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
    width: 33%;
    min-height: 240px;
}

.all-gigs-item > h1 {
    color: #F4F2EF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.all-gigs-item:hover {
    filter: brightness(1.5);
    -webkit-filter: brightness(1.5);
    scale: 1.01;
    cursor: pointer;
}

/* Client Scrollable List Container */

.client-card-container {
    padding: 12px;
    height: 270px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.client-card {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: transparent;
    box-shadow: 0px 4px 10px 6px #1b1b1b50;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 6px;
    margin-top: 6px;
    border-top: 2px solid #F4F2EF;
    border-bottom: 2px solid #F4F2EF;
}

.client-card:hover {
    filter: brightness(1.5);
    -webkit-filter: brightness(1.5);
    cursor: pointer;
    scale: 1.01;
}

.client-card > img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
}

.view-client-image {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.view-client-image::after {
    border-radius: 8px;
    width: 320px;
    height: 64px;
    align-self: center;
    content: "";
    background-color: #46CD6E;
    position: absolute;
    z-index: 0;
}

.view-client-image > img {
    position: relative;
    z-index: 2;
    border-radius: 50%;
    width: 128px;
    height: 128px;
    align-self: center;
}

.client-name {
    color: #F4F2EF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.client-info {
    margin-left: 12px;
    color: #F4F2EF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.client-info > span {
    color: #46CD6E;
}

.container-scroll {
    height: inherit;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 12px;
}

/* Orders Scrollable List Display Container */

.order-container-scroll {
    height: inherit;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 12px;
}


.order-list-item {
    padding: 12px 0px;
    border-top: #F4F2EF solid 1px;
    border-bottom: #F4F2EF solid 1px;
    margin-top: 6px;
    margin-bottom: 6px;
    transition: all 150ms ease-in-out;
}

.order-list-item:hover {
    cursor: pointer;
    background-color: #1e1e1e;
}

.order-list-item > .order-list-item-header > h1 { 
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
    color: #F4F2EF;
}

.order-list-item > .order-list-item-header, .order-list-item-body > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
    color: #F4F2EF;
}

.order-list-item > .order-list-item-header, .order-list-item-body > div > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
    color: #F4F2EF;
}


.order-list-item > .order-list-item-header, .order-list-item-body > div > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
    color: #46CD6E;
}

.card-container-small {
    border-radius: 4px;
    background-color: #101010;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6px;
}

.card-container-small > h1 {
    font-size: 48px;
    color: #F4F2EF;
}

.card-container-small > p {
    font-size: 24px;
    color: #46CD6E;
}

/* Income Flow Carousel Unique Classes */

.carousel {
    position: relative;
    width: 100%;
    height: inherit;
}

.slide {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: inherit;
    opacity: 0;
}

.slide > div > hr {
    border: 10px solid #46CD6E;
    border-radius: 8px;
    margin-bottom: 12px;
}

.slide > div > h2 {
    font-size: 18px;
    color: #fefefe;
    margin-bottom: 12px;
}

.slide > div > h2 > span {
    color: #46cd6e;
}

.active-slide {
    animation: slideFromRight 150ms ease-in-out forwards;
}

@keyframes slideFromRight {
    0%{
        opacity: 0.01;
        left: 120px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

@media (max-width: 450px){
    .card-item-placeholder-header > h1{
        font-size: 20px !important;
    }
    .card-item-placeholder-header > p{
        font-size: 14px !important;
    }

}
