@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html {
    overflow: hidden;
}

label {
    color: #46CD6E;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;

}

